<template>
  <div>
    <loading-overlay :active="Loading" :is-full-page="true" loader="bars" />
    <ClienteModal :modal="clieModal" @cerrarModal="clieModal=false" @child-refresh="refrescarCliente=true" />
    <ActividadModal :modal="actModal" @cerrarModal="actModal=false " @child-refresh="refrescarActividad=true" />
    <ClienteTransaccionesModal 
      :modal.sync="clietraModalCreate" 
      :ClientItem="ClientItem"
      :title="titleModal"
      @close="closeModal"   
    />
    <ActividadClientesModal :modal="actClieModal" @cerrarModal="actClieModal=false" @child-refresh="refrescarActividad=true" @getDataId="getDataId"/>
    <CCard>
      <CCardHeader class="text-center bg-dark text-white">
        <b>{{$t('label.clientsActivitiesList')}}</b>
      </CCardHeader>
      <CCardBody>
        <CustomTabs :active-tab="Tab" @update:activeTab="handleTab">
          <CustomTab :title="$t('label.client')" icon="user">
            <CRow class="mt-2">
              <CCol sm="12" class="d-flex justify-content-end py-2">
                <CButton 
                  color="add"
                  class="d-flex align-items-center"
                    v-c-tooltip="{
                        content: $t('label.client'),
                        placement: 'top-end'
                    }"
                  @click="clieModal=true">
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nuevo') }}</span>
                </CButton>
              </CCol>
              <CCol sm="12">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items="itemsCliente"
                  :fields="fieldsCliente"
                  :loading="LoadingCliente"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                   
                >
                  <template #loading>
                    <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Detalle="{item}">
                    <td class="center-cell">    
                      <CButton color="watch" size="sm" class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.transactions')+' '+$t('label.client'),
                          placement: 'top-end'
                        }" 
                        @click="clietraModal(item)">
                        <CIcon name="clienteTransacciones" />
                      </CButton>                 
                      <CButton color="plan" size="sm" class="mr-1"
                        v-c-tooltip="{
                          content: $t('label.activities')+' '+$t('label.client'),
                          placement: 'top-end'
                        }" 
                        @click="abrirActividad(item)" 
                        >
                        <CIcon name="clientesActividad" />
                      </CButton>
                      <CButton color="edit" class="mr-1" size="sm" 
                        v-c-tooltip="{
                          content: $t('label.edit')+' '+$t('label.client'),
                          placement: 'top-end'
                        }" 
                        @click="clieModal=item" >
                        <CIcon name="pencil" />
                      </CButton>
                    </td>
                  </template>
                </dataTableExtended>
              </CCol>
            </CRow>
          </CustomTab>
          <CustomTab :title="$t('label.AssociationActivityClient')" icon="actividadesCliente">
            <CRow class="mt-2">
              <CCol sm="7" class="form-inline">
                <label class="mr-2">
                  {{$t('label.activity')}}
                </label>
                <v-select 
                  :filter="fuseSearchActividades"
                  :options="actividadesOptions"
                 
                  v-model="actividad"
                  :placeholder="$t('label.select')"
                  class="w-75"
                  @input="isActivitycleanData"
                />
              </CCol>
              <CCol sm="5" class="d-flex justify-content-end py-2">
                <CButton 
                  color="add"
                  class="d-flex align-items-center"
                    v-c-tooltip="{
                        content: $t('label.Association'),
                        placement: 'top-end'
                    }"
                  @click="abrirActividadClientes(true)" >
                  <CIcon name="cil-playlist-add"/><span class="ml-1">{{ $t('label.nueva') }}</span>
                </CButton>
              </CCol>
              <CCol sm="12">
                <dataTableExtended
                  class="align-center-row-datatable"
                  :items="ComputedClientlist"
                  :fields="fieldsActividadCliente"
                  :loading="LoadingActividad"
                  column-filter
                  :noItemsView="tableText.noItemsViewText"
                  :table-filter="tableText.tableFilterText"
                  :items-per-page-select="tableText.itemsPerPageText"
                  :items-per-page="5"
                  hover
                  small
                  sorter
                  pagination
                   
                >
                <template #loading>
                    <loading/>
                  </template>
                  <template #Status="{item}">
                    <td class="center-cell">
                      <CBadge :color="getBadge(item.Status)">
                        {{ $t('label.'+item.Status) }}
                      </CBadge>
                    </td>
                  </template>
                  <template #Details="{item, index}" >
                      <td class="text-center">
                          <CButton
                            shape="square"
                            color="wipe"
                            size="sm"
                            v-c-tooltip="{
                              content: $t('label.Inactivate')+' '+$t('label.Association'),
                              placement: 'top-end'
                            }"
                            @click="cambiarEstatus(item, index)"
                          >
                            <CIcon name="cil-trash" />
                          </CButton>
                      </td>
                  </template>
                  
                </dataTableExtended>
              </CCol>
            </CRow>          
          </CustomTab>
        </CustomTabs>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import CustomTabs from '@/components/tabs/CustomTabs';
import CustomTab from '@/components/tabs/CustomTab';
import { DateFormater } from '@/_helpers/funciones';
import ClienteModal from './cliente-modal';
import ActividadModal from './actividad-modal';
import { mapState } from 'vuex';
import ClienteTransaccionesModal from './cliente-transacciones-modal';
import ActividadClientesModal from './actividad-clientes-modal';
import General from '@/_mixins/general';
import Fuse from "fuse.js";


//DATA
function data () {
  return {
    //actividad: null,
    actividad: '',
    itemsCliente: [],
    itemsActividad: [],
    clientes: [],
    actividades: [],    
    clieModal: false,
    actModal: false,
    actClieModal: false,
    clietraModalCreate: false,
    LoadingActividad: false,
    LoadingCliente: false,
    Loading: false,
    refrescarCliente: false,
    refrescarActividad: false,
    TpClientId: '',
    Tab: 0,
    titulo: '',
    isActive: false,
    ClientItem:{},
    ClientId:'',
    titleModal:''
    
  }
}

//METHOD
function handleTab(tab) {
  this.Tab = tab;
}
function listarClientes () {
  this.itemsCliente = [];
  this.clientes = [];
  this.LoadingCliente = true;
  
  let listado = Array;
  this.$http.get("Client-list", { filter: 'ALL' })
  .then(response => {
    listado = [...response.data.data];

    this.clientes = listado.filter(ob => ob.Status==="ACTIVO" );
    this.itemsCliente = listado.map(listado => Object.assign({}, this.itemsCliente, {
      Nro: listado.Nro,
      Cliente: listado.ClientName,
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      ClientId: listado.ClientId,
      ClientRif: listado.ClientRif,
      ClientAddress: listado.ClientAddress,
      PrimaryEmail: listado.PrimaryEmail,
      SecondaryEmail: listado.SecondaryEmail,
      PrimaryPhone: listado.PrimaryPhone,
      SecondaryPhone: listado.SecondaryPhone,
      Representative: listado.Representative,
      FgGovernment: listado.FgGovernment,
      ClientAlias: listado.ClientAlias ? listado.ClientAlias:'',
      FgActClient: listado.FgActClient,
      Color: listado.Color,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': '',
    }));

  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.LoadingCliente = false;
  });
}

function fuseSearchActividades(options, search) {
  const fuse = new Fuse(options, {
    keys: ["TpClientName"],
    shouldSort: true
  });
  return search.length
    ? fuse.search(search).map(({ item }) => item)
    : fuse.list;
}

function listarActividades () {
  this.actividades = [];

  this.$http.get("TpClient-list", { Filter: 'ACTIVO' })
  .then(response => {
    this.actividades = [...response.data.data];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
}

function listarClientesByActividad(activ) {
  this.itemsActividad = [];
  this.LoadingActividad = true;
 

  let listado = Array;
  this.$http.get("Client-list-by-activity", { TpClientId: activ })
  .then(response => {
    let list = response.data.data;
      if (list.length != 0) {
        this.itemsActividad = list;
      }
      this.LoadingActividad = false;
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  }).then(() => {
    this.LoadingActividad = false;
  });
}
//asigna actividades a un cliente
function abrirActividad(item) {
  this.actModal = {Item: item, Clientes: this.clientes, Actividades: this.actividades };
}
//asigna clientes a una actividad item=true
function abrirActividadClientes(item) {
  if (this.actividad === null)
    {
        this.actividad = '';
    }
  this.actClieModal = { Item: item, Actividad: this.actividad, Clientes: this.clientes, Actividades: this.actividades };
}

function getDataId(data) {

  this.actividad = '';
  let array = [];
  this.$http.get("TpClient-by-id", { TpClientId: data })
  .then(response => {
    array = [...response.data.data];
    if(array.length > 0)
      this.actividad = array[0];
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  });
  this.listarClientesByActividad(data);
}

function actividadSearch() {
  this.listarClientesByActividad(this.actividad.TpClientId);
}

function cambiarEstatus(item, index) {

  this.$swal
          .fire(this.alertProperties({
              text: `${this.$t('label.changeStatusQuestion')+'?'}`,
          }))
        .then((result) => {
        if (result.isConfirmed) {
          this.SubmitCambiarEstatus(item);
        }
      });
}

function SubmitCambiarEstatus(item){
    let  ClientTpJson = {
        ClientTpId: item.ClientTpId,
        ClientId: item.ClientId,
        TpClientId: item.TpClientId,
        Status: item.FgActClientTp ? 0:1,
        UserId: this.user.UserId,
        CompanyBranchId: item.CompanyBranchId ? item.CompanyBranchId : '',
      };
      
    this.$http.put("ClientActivity-update", ClientTpJson, { root: 'ClientTpJson' })
    .then(response => {
      this.listarClientesByActividad(item.TpClientId);
      this.$notify({
        group: 'container',
        title: '¡'+this.$t('label.success')+'!',
        text: response.data.data[0].Response,
        type: "success"
      });
    }).catch(err => {
      this.$notify({
        group: 'container',
        title: '¡Error!',
        text: err,
        type: "error"
      });
    }).then(() => {

    });

}

function clietraModal(item){
  this.ClientItem = item;
  this.clietraModalCreate = true;
  this.titleModal= this.$t('label.transactionsClient')+': '+this.ClientItem.Cliente;
}

function closeModal(){
  this.ClientItem = {};
  this.clietraModalCreate= false;
  this.titleModal = '';
}


//COMPUTED
function actividadesOptions() {
  let _lang = this.$i18n.locale;

  return this.actividades.map((actividad) => Object.assign({}, actividad, {
    label: _lang=='en' ? actividad.TpClientNameEn : actividad.TpClientNameEs,
    value: actividad.TpClientId
  }));
}

function ComputedClientlist() {
  let _lang = this.$i18n.locale;
   return this.itemsActividad.map((listado) => {
     return {
      Nro: listado.Nro,
      Cliente: listado.ClientName,
      ClientAlias: listado.ClientAlias ? listado.ClientAlias : 'N/A',
      TpClientName: _lang=='en' ? listado.TpClientNameEn : listado.TpClientNameEs,
      BranchName: listado.BranchName ? listado.BranchName : 'N/A',
      Usuario: listado.TransaLogin ? listado.TransaLogin:'N/A',
      Fecha: listado.TransaRegDate ? DateFormater.formatOnlyDateWithSlash(listado.TransaRegDate) : 'N/A',
      Status: listado.Status,
      ClientTpId: listado.ClientTpId,
      TpClientId: listado.TpClientId,
      CompanyBranchId: listado.CompanyBranchId,
      ClientId: listado.ClientId,
      ClientRif: listado.ClientRif,
      FgActClientTp: listado.FgActClientTp,
      _classes: listado.Status === "INACTIVO" ? 'table-danger': ''
     };
   });
 }




function fieldsCliente(){
  return[
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
     _style: 'width:1%; text-align:center;',
     _classes:'text-uppercase text-center center-cell'
    },
    { key: 'Cliente', label: this.$t('label.client'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'ClientAlias', label: this.$t('label.alias'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'ClientRif', label: this.$t('label.rifNumber'), _style: 'width:12%',_classes: 'text-uppercase text-center center-cell'},
    { key: 'Usuario', label: this.$t('label.user'), _style: 'width:10%', _classes: 'text-uppercase text-center center-cell'},
    { key: 'Fecha',  label: this.$t('label.date'), _style: 'width:10%', _classes: 'text-center center-cell'},
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%', _classes: 'text-center center-cell'},
    { key: 'Detalle', label: '', sorter: false, filter: false, _classes:'text-center center-cell', _style: 'width:12%'  }
    
  ]
}

function fieldsActividadCliente(){
  return[
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
     _style: 'width:1%; text-align:center;',
     _classes:'text-uppercase text-center center-cell'
    },
    { key: 'Cliente', label: this.$t('label.client'), _classes: 'text-uppercase text-center center-cell'},
    { key: 'ClientRif', label: this.$t('label.rifNumber'), _style: 'width:12%', _classes: 'text-uppercase text-center center-cell'},
    { key: 'TpClientName',  label: this.$t('label.activity'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'BranchName',  label: this.$t('label.branch'), _classes: 'text-uppercase text-center center-cell' },
    { key: 'Usuario', label: this.$t('label.user'), _style: 'width:10%', _classes:'text-uppercase text-center center-cell' },
    { key: 'Fecha', label: this.$t('label.date'), _style: 'width:10%', _classes: 'text-center center-cell ' },
    { key: 'Status', label: this.$t('label.status'), _style: 'width:10%', _classes: 'text-center center-cell' },
    { key: 'Details', label: '', sorter: false, filter: false, _classes:'text-center center-cell', _style: 'width:5%'  }
  
  ]
}

function isActivitycleanData(){
  return this.itemsActividad = [];
}

export default {
  name: 'index',
  mixins: [General],
  data,
  components: {
    ClienteModal,
    ActividadModal,
    ClienteTransaccionesModal,
    ActividadClientesModal,
    CustomTabs,
    CustomTab
  },
  props: {
    
  },
  methods: {
    listarClientes,
    listarActividades,
    listarClientesByActividad,
    fuseSearchActividades,
    abrirActividad,
    abrirActividadClientes,
    getDataId,
    actividadSearch,
    handleTab,    
    cambiarEstatus,
    SubmitCambiarEstatus,
    clietraModal,
    closeModal,
    isActivitycleanData
  },
  watch: {
    refrescarCliente: function () {
      if (this.refrescarCliente) {
        this.listarClientes();
        this.refrescarCliente = false;
      }
    },
    refrescarActividad: function () {
      if (this.refrescarActividad) {
        this.listarActividades();
        this.refrescarActividad = false;      
      }
    },
    actividad: function(item) {
      if(item){
        this.listarClientesByActividad(item.TpClientId);
      } 
    },
    Tab: async function (newValue) {
      if (newValue==0) {
        this.listarClientes();
      } else if (newValue==1) {
        this.listarActividades();
      }
    },
  },
  computed: {
    actividadesOptions,
    fieldsCliente,
    fieldsActividadCliente,
    ComputedClientlist,
    ...mapState({
        user: state => state.auth.user,
    })
  },
  mounted() {
    this.listarClientes();
    this.listarActividades();
  }
}
</script>

<style lang="scss">
.center-cell {
  text-align: center;
}
 
</style>